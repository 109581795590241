@font-face {
  font-family: 'morabba';
  src: url('../fonts/Morabba-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'morabba';
  src: url('../fonts/Morabba-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'morabba';
  src: url('../fonts/Morabba-Light.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'morabba';
  src: url('../fonts/Morabba-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'morabba';
  src: url('../fonts/Morabba-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}



* {
	font-family: "morabba" !important;
  font-weight: 500;
}